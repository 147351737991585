import React, { useEffect, useState } from "react";
import "./Cart.css";
import Header from "../layout/Header/Header";
import Footer from "../layout/Footer/Footer";
import { useSelector, useDispatch } from "react-redux";
import { getItems_Wishlist, moveItemToWishlist, getItems_Cart, addQuantityToCart, decreaseQuantityToCart, removefromCart, add_Addresses, get_Addresses } from "../../actions/cartAction";
import { Link } from 'react-router-dom'

function Cart({ history }) {
  const dispatch = useDispatch();
  const { shippingInfo, cartItems, wishlist } = useSelector((state) => state.cart);
  const { user, isAuthenticated } = useSelector((state) => state.user);
  const [dollar, setDollar] = useState("");
  const [cartBanner, setCartBanner] = useState('');

  function getDollarPrice() {
    fetch("/api/dollar")
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setDollar(data[0].value);
        }
      });
  }

  var subtotal = cartItems.reduce(
    (acc, item) => acc + item.quantity * item.selling_price,
    0
  );

  const shippingCharges = cartItems.length ? subtotal > 1000 ? 0 : 200 : 0;

  const tax = 0;
  // const tax = subtotal * 0.18;
  subtotal = subtotal - tax

  const totalPrice = subtotal + tax; //shippingCharges

  // const address = `${shippingInfo[0].address1}, ${shippingInfo[0].address2}, ${shippingInfo[0].city}, ${shippingInfo[0].state}, ${shippingInfo[0].zip}, ${shippingInfo[0].country}`;

  const proceedToPayment = () => {
    const data = {
      subtotal,
      shippingCharges,
      tax,
      totalPrice,
    };

    localStorage.setItem("orderInfo", JSON.stringify(data));
  };

  async function removeItemfromCart(index, product_id, product) {
    dispatch(removefromCart(product_id, isAuthenticated, product));
  }
  useEffect(() => {
    window.scrollTo(0, 0)
    getDollarPrice()
  }, [])


  async function movetoWishlist(index, product_id) {
    dispatch(moveItemToWishlist(product_id));
  }

  async function decreaseQuantity(index, cart_id, product_id) {
    var temp = [...cartItems]
    if (temp[index].quantity > 1) {
      dispatch(decreaseQuantityToCart(product_id, isAuthenticated));
    }
  }
  async function increaseQuantity(index, cart_id, product_id) {
    var temp = [...cartItems]
    if (temp[index].available_quantity > temp[index].quantity) {
      dispatch(addQuantityToCart(product_id, isAuthenticated));
    } else {
      alert(`Only ${temp[index].available_quantity} quantity available.`)
    }
  }
  function getCartBanner() {
    fetch("/api/banner/cart")
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setCartBanner(data[0]);
        }
      });
  }

  useEffect(() => {
    getCartBanner();
    dispatch(getItems_Cart(isAuthenticated));
    dispatch(get_Addresses());
    dispatch(getItems_Wishlist(isAuthenticated));
  }, []);

  function handleCheckout() {
    history.push("/checkout");
  }


  const handleClick = (url, id) => {
    history.push(url, { id });
  }


  return (
    <>
      {/* <Header></Header> */}
      <div className="cartHeader text-center">
        {cartBanner && cartBanner.name && cartBanner.name !== "" &&
          <img src={process.env.REACT_APP_BASE_URL + cartBanner.name} />}
        <h2 className="pTitle">Cart</h2>
      </div >
      <div className="categoryBreadcrum">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="pagelinks">
                <ul>
                  <li>
                    <Link to="/">Home</Link>
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="5"
                        height="7"
                        viewBox="0 0 5 7"
                        fill="none"
                      >
                        <path
                          d="M4.19983 3.23364L1.42249 0.368408L0.930298 0.825439L3.26819 3.23364L0.859985 5.69458L1.33459 6.16919L4.02405 3.39185L4.19983 3.23364Z"
                          fill="black"
                        />
                      </svg>
                    </span>
                  </li>
                  <li>
                    <a>Cart</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="myCart p-both-50">
        <div className="container">
          <div className="row">
            <div className={`col-md-${cartItems.length === 0 ? "12" : "7"}`}>
              <div className="cartTable">
                <table width={"100%"}>
                  <thead>
                    <tr>
                      <th style={{ width: "50%" }}>Item</th>
                      <th>Product Price</th>
                      <th>Product Quantity</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      cartItems.length === 0 ? (
                        <tr>
                          <td>
                            <div className="cartProductDetail">Cart is Empty</div>
                          </td>
                        </tr>) : (
                        cartItems?.map((product, index) => {
                          return (<tr key={index}>
                            <td>
                              <div className="cartProductDetail">
                                <div className="cpImage" >
                                  <a onClick={() => handleClick(`/singleproduct/${product.name}/p/${product.product_id}/${product.category}`, product.product_id)}>
                                    <img src={process.env.REACT_APP_BASE_URL + product.image} />
                                  </a>
                                </div>
                                <div className="cpdata">
                                  <a className="ppTitle" onClick={() => handleClick(`/singleproduct/${product.name}/p/${product.product_id}/${product.category}`, product.product_id)}>
                                    {product.name}
                                  </a>
                                  <p>
                                    <b>Category:</b> {product.category}
                                  </p>
                                  <p>
                                    <b>Color:</b> {product.color}
                                  </p>
                                  <p>
                                    <b>Size:</b> {product.size}
                                  </p>
                                  <p>
                                    <b>Quantity:</b> {product.quantity}
                                  </p>
                                  <div className="remoBtns">
                                    {/* <a onClick={() => handleClick(`/singleproduct/${product.name}/p/${product.product_id}`, product.product_id)}>
                                      <i className="fa-solid fa-pen"></i> Edit
                                    </a> */}
                                    <a onClick={() => removeItemfromCart(index, product.product_id, product)} >
                                      <i className="fa-solid fa-trash"></i> Remove
                                    </a>
                                    {isAuthenticated && <a onClick={() => movetoWishlist(index, product.product_id)}>
                                      <i className="fa-solid fa-heart"></i> Move To
                                      Wishlist
                                    </a>}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>Rs {product.selling_price}</td>
                            <td>
                              <div className="quantity">
                                <div className="number">
                                  <span className="minus" onClick={() => decreaseQuantity(index, product.cart_id, product.product_id)}>
                                    <i className="fa-solid fa-minus"></i>
                                  </span>
                                  <input type="text" placeholder="0" value={product.quantity} />
                                  <span className="plus" onClick={() => increaseQuantity(index, product.cart_id, product.product_id)}>
                                    <i className="fa-solid fa-plus"></i>
                                  </span>
                                </div>
                              </div>
                            </td>
                            <td>Rs {product.selling_price * product.quantity}</td>
                          </tr>
                          )
                        }
                        ))}

                  </tbody>
                </table>
              </div>
              <Link to="/" className="contiShop">
                <i className="fa-solid fa-arrow-left-long"></i> Continue
                Shopping
              </Link>
            </div>
            {cartItems.length ?
              <div className="col-md-5">
                <div className="cartSummary">
                  <h2>Summary</h2>
                  <table width={"100%"}>
                    <tr>
                      <td>Subtotal</td>
                      <td>Rs {subtotal} || $ {subtotal && dollar ? (subtotal / dollar).toFixed(2) : "..."} </td>
                    </tr>
                    {/* <tr>
                      <td>Shipping Charges</td>
                      <td>Rs {shippingCharges}</td>
                    </tr> */}
                    {/* <tr>
                      <td>Estimated Tax</td>
                      <td>Rs {tax}</td>
                    </tr> */}
                    <tr>
                      <td>
                        <b>Order Total</b>
                      </td>
                      <td>
                        <b>Rs {totalPrice.toFixed(2)} || $ {subtotal && dollar ? (subtotal / dollar).toFixed(2) : "..."}</b>
                      </td>
                    </tr>
                  </table>
                  <button className="blackbtn" onClick={handleCheckout} >Proceed To Checkout</button>
                  <p>
                    Need help? Call Us at{" "}
                    <a href="tel:+91 9872407145">+91 9872407145</a>
                  </p>
                </div>
              </div> : ""}
          </div>
        </div>
      </div>
      <div className="footerTop">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-regular fa-paper-plane"></i>
                <p>We ship worldwide</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-brands fa-whatsapp"></i>
                <p>Chat on Whatsapp</p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="ftCard">
                <i class="fa-solid fa-mobile-screen-button"></i>
                <p>
                  Call & Mail us to book a styling <br />
                  appointment +91 9872407145
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
}

export default Cart;
